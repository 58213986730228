import { APP_INITIALIZER, ApplicationConfig, ErrorHandler } from '@angular/core';
import { PreloadAllModules, provideRouter, Router, withPreloading } from '@angular/router';

import { routes } from './app-routes';
// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Sentry from '@sentry/angular';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { HttpTokenInterceptorService } from './core/interceptors/http-token-interceptor.service';
import { HttpTransferStateInterceptorService } from './core/interceptors/http-transfer-state-interceptor.service';
import { provideAnimations } from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, withPreloading(PreloadAllModules)),
        provideHttpClient(
            withFetch(),
            withInterceptorsFromDi()
        ),
        provideAnimations(),
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-empty-function
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpTokenInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpTransferStateInterceptorService,
            multi: true,
        },
    ]
};
